import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './Pages/HomePage/HomePage';
import Event from './Pages/Event/Event';
import FAQ from './Pages/FAQ/FAQ';
import Blog from './Pages/Blog/Blog';


function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/Event' element={<Event />} />
        <Route path='/home' element={<HomePage />} />
        <Route path='/faq' element={<FAQ />} />
        <Route path='/blog' element={<Blog />} />
      </Routes>
    </Router>
  );
}

export default App;
