import React from "react";

//style
import './Blog.css'
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import guest from "../../Assets/guest.jpeg";
import dreamaxflyer from "../../Assets/dreamaxflyer.jpeg";


function Blog() {

    return (
        <div className="container-fluid">
            <div className="row">
                <Header />
                <div className="home ">
                    <div className="manny mb-4">
                        <h1 className=" mt-1 mx-3">
                            Our Latest Update
                        </h1>
                        <div className="eventnext p-3 d-flex align-items-center justify-content-between">
                            <div className="manny text-center mx-5">
                                <h3 className="mt-5 mx-3">
                                    We’re Making Learning More Affordable – Here’s How!
                                </h3>
                                <h5 className="mx-3 mt-5">
                                    At DesignCave, we believe that financial barriers shouldn’t stop you from becoming a great designer. That’s why we’ve partnered with Dreamax HQ to secure exclusive discounts on courses taught by renowned instructor Davio White—a multiple-award-winning designer known for his unique and innovative approach to design.
                                </h5>
                                <h5 className="mt-5">With his 3 courses available on Dreamax HQ, we’re making it easier for our community to learn directly from the best—without breaking the bank.</h5>
                            </div>
                            <div className="box-event">
                                <img src={dreamaxflyer} alt="Img" />
                            </div>
                        </div>
                    </div>
                    <div className="top align-items-center justify-content-center ">
                        {/* <img src="/"></img>           */}
                        <div className="toptext p-3">
                            <h1 className="mt-1 ">
                                What’s the Deal?
                            </h1>
                            <h5 className="">
                                As a DesignCave member, you get access to the following course discounts when purchasing on Dreamax HQ:
                            </h5>
                            <p className="mt-3">
                                <ul>
                                    <strong>
                                        <li className="mb-2">
                                            Complete UIUX Design Course
                                        </li>
                                        <li className="mb-2">
                                            Brand Identity design from sketch to handoff
                                        </li>
                                        <li className="mb-2">
                                            Master Figma for UI designs
                                        </li>
                                    </strong>
                                </ul>
                                <span>
                                    ✅
                                    ✅
                                    That means less financial stress and more learning!</span>
                            </p>
                        </div>
                    </div>
                    <div className="top align-items-center justify-content-center ">
                        {/* <img src="/"></img>           */}
                        <div className="toptext p-3">
                            <h1 className="mt-1 ">
                                How to Get Your Discount?
                            </h1>
                            <h5 className="">
                                We’re making it easy for anyone to access discounts on essential design courses:
                            </h5>
                            <p className="mt-3">
                                <ul>
                                    <strong>
                                        <li className="mb-2">
                                            Complete UIUX Design Course – ₦200 OFF
                                        </li>
                                        <li className="mb-2">
                                            Brand Identity design from sketch to handoff – ₦200 OFF
                                        </li>
                                    </strong>
                                </ul>
                            </p>
                            <h4 className="mt-4">
                                Simply use coupon code:
                                <strong><span>
                                    "dcave"
                                </span></strong>
                                at checkout to enjoy your discount!
                            </h4>
                            <h4 className="mt-5">
                                <strong><span>
                                    But wait… there’s more!
                                </span></strong>
                            </h4>
                        </div>
                    </div>
                    <div className="top align-items-center justify-content-center ">
                        {/* <img src="/"></img>           */}
                        <div className="toptext p-3">
                            <h1 className="mt-1 ">
                                Exclusive Discount for Community Members
                            </h1>
                            <h5 className="mt-2">
                                For those inside the DesignCave Community, we’re unlocking an even bigger opportunity:
                            </h5>
                            <p className="mt-3">
                                <ul>
                                    <strong>
                                        <li className="mb-2">
                                            Master Figma for UI Designs – ₦2,000 OFF!
                                        </li>
                                    </strong>
                                </ul>
                            </p>
                            <h5>
                                However, this discount is <strong> ONLY</strong> available to community members. If you're not yet in, now is the best time to join DesignCave and unlock more rewards!
                            </h5>
                        </div>
                    </div>
                    <div className="top align-items-center justify-content-center">
                        {/* <img src="/"></img>           */}
                        <div className="toptext p-3">
                            <h1 className="mt-1 ">
                                Why Join DesignCave?
                            </h1>
                            <h5 className="mt-2">
                                Being a part of our community isn’t just about learning, it’s about growth, support, and unlocking exclusive perks. By joining us, you gain access to:
                            </h5>
                            <p className="mt-3">
                                <ul>
                                    <strong>
                                        <li className="mb-2">
                                            More discounts & rewards on future courses
                                        </li>
                                        <li className="mb-2">
                                            Expert guidance & mentorship in UI/UX & product design
                                        </li>
                                        <li className="mb-2">
                                            A thriving community of like-minded designers
                                        </li>
                                        <li className="mb-2">
                                            Opportunities to grow your career with valuable resources
                                        </li>
                                    </strong>
                                </ul>
                            </p>
                        </div>
                    </div>

                    <div className="event d-flex flex-wrap align-items-center justify-content-between mt-5">
                        <div className="eventdetails2 d-flex flex-wrap  align-items-center justify-content-center mb-2 ">
                            <div className="details p-2">
                                <h1 className="mt-2">
                                    <strong>How to Get Started? </strong></h1>
                                <h4 className="mt-4">
                                    <span> <strong>Use coupon code: "dcave" </strong></span>to get instant discounts on UI/UX for Beginners & Brand Design.
                                </h4>
                                <h4 className="mt-4">
                                    Want the<span> <strong>Master Figma</strong></span> discount? <strong>Join the DesignCave community</strong> to unlock it!
                                </h4>
                            </div>
                        </div>
                    </div>
                    <a href="#">
                        <button id="ready" className="btn btn-custom mt-5">Next Story</button>
                    </a>
                </div>
                <Footer />
            </div>
            <script async src="https://eocampaign1.com/form/b8ab6c36-bc82-11ef-89f5-85263dcbd4b2.js" data-form="b8ab6c36-bc82-11ef-89f5-85263dcbd4b2"></script>
        </div>
    );
}

export default Blog