import React from "react";

//style
import './Event.css'
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import guest from "../../Assets/guest.jpeg";


function Event() {

    return (
        <div className="container-fluid">
            <div className="row">
                <Header />
                <div className="home ">
                    <div className="manny mb-4">
                        <h1 className=" mt-1 mx-3">
                            NEXT EVENT
                        </h1>
                        <div className="eventnext p-3 d-flex align-items-center justify-content-between">
                            <div className="manny text-center mx-5">
                                <h3 className="mt-5 mx-3">
                                    Program: <span> The Designer’s Playbook</span>
                                </h3>
                                <h3 className="mx-3">
                                    Theme: <span> The Power of User Research: Designing with Purpose</span>
                                </h3>
                                <h3 className="mx-3">
                                    Date: <span> March 29th, 2025</span>
                                </h3>
                            </div>
                            <div className="box-event">
                                <img src={guest} alt="Img" />
                            </div>
                        </div>
                    </div>
                    <div className="top align-items-center justify-content-center d-flex">
                        {/* <img src="/"></img>           */}
                        <div className="toptext p-3">
                            <h1 className="mt-1 ">
                                OUR UPCOMING
                            </h1>
                            <p className="text-center">
                                EVENTS <span> 2024</span>
                            </p>
                        </div>
                    </div>
                    <div className="event p-3 align-items-center justify-content-center mt-2">
                        <div className="eventdetails d-flex flex-wrap  align-items-center justify-content-center ">
                            <div className="date">
                                <p className="mt-3">Dec 27</p>
                            </div>
                            <div className="details  p-2">
                                <p className="mt-3">
                                    <span> <strong>Theme: </strong></span>    DesignCave at One: Honoring the Journey, Inspiring the Future. </p>
                                <p className="mt-3">
                                    <span> <strong>Guest Speaker: </strong></span>   DesignCave Co-Founder and other members </p>
                            </div>
                        </div>
                        <div className="horizontal-line w-50 d-flex align-items-center justify-content-center"></div>
                    </div>

                    <h1 className="d-flex align-items-center justify-content-center mt-5">2025</h1>

                    <div className="event d-flex flex-wrap align-items-center justify-content-between mt-5">
                        <div className="eventdetails2 d-flex flex-wrap  align-items-center justify-content-center mb-2 ">
                            <div className="details p-2">
                                <p className="mt-3 ">
                                    <span> <strong>Program </strong></span>  Design Talk Day. </p>
                                <p className="mt-3 ">
                                    <span> <strong>Theme: </strong></span>  Fresh Perspectives: Starting 2025 with Design in Focus. </p>
                                <p className="mt-3">
                                    <span> <strong>Insight:  </strong></span>   The focus is on helping the audience think creatively, solve problems, and approach design with fresh energy for the year ahead.</p>
                                <p className="mt-3">
                                    <span> <strong>Guest Speaker: </strong></span>  KemsDesign. </p>
                            </div>
                            <div className="date2">
                                <p className="mt-3">JAN 31</p>
                            </div>
                        </div>
                        <div className="horizontal"></div>

                        <div className="eventdetails2 d-flex flex-wrap align-items-center justify-content-center mb-2">
                            <div className="date">
                                <p className="mt-3">Feb 28</p>
                            </div>
                            <div className="details p-2 ">
                                <p className="mt-3 ">
                                    <span> <strong>Program </strong></span>  Design Talk Day. </p>
                                <p className="mt-3 ">
                                    <span> <strong>Theme: </strong></span>  Designing for Impact, Beyond Aesthetics. </p>
                                <p className="mt-3 ">
                                    <span> <strong>Insight: </strong></span> This session will focus on how design goes beyond looking good—it’s about solving real problems and creating experiences that make people’s lives better. We’ll discuss how designers can create work that’s both beautiful and impactful.</p>
                                <p className="mt-3">
                                    <span> <strong>Guest Speaker:</strong></span> Blessing Anyanwu</p>
                            </div>
                            <div className="horizontal"></div>
                        </div>
                    </div>

                    <div className="event d-flex flex-wrap align-items-center justify-content-between mt-5">
                        <div className="eventdetails2 d-flex flex-wrap align-items-center justify-content-center mb-2">
                            <div className="details p-2">
                                <p className="mt-3">
                                    <span><strong>Program:</strong></span> Enlightenment Session (Free 4-Week Program).
                                </p>
                                <p className="mt-3">
                                    <span><strong>Theme:</strong></span> Fundamentals of UI/UX Design.
                                </p>
                                <p className="mt-3">
                                    <span><strong>Insight:</strong></span> Dive into the foundations of design thinking, exploring the basics of user interfaces and user experiences. Learn how to craft intuitive designs that meet users' needs and set the stage for your design journey.
                                </p>
                                <p className="mt-3">
                                    <span><strong>Tutor:</strong></span> Experienced Designer.
                                </p>
                            </div>
                            <div className="date2">
                                <p className="mt-3">FEB 18 - MAR 17</p>
                            </div>
                        </div>

                        <div className="eventdetails2 d-flex flex-wrap align-items-center justify-content-center mb-2">
                            <div className="date">
                                <p className="mt-3">MAR 29</p>
                            </div>
                            <div className="details p-2">
                                <p className="mt-3">
                                    <span><strong>Program:</strong></span>
                                    The Designer’s Playbook.
                                </p>
                                <p className="mt-3">
                                    <span><strong>Theme:</strong></span> The Power of User Research: Designing with Purpose
                                </p>
                                <p className="mt-3">
                                    <span><strong>Insight:</strong></span>Understanding users is the backbone of great design. Let’s explore how research shapes better products and experiences.

                                </p>
                                <p className="mt-3">
                                    <span><strong>Guest Speaker:</strong></span> Oluwatosin V. Adejayan (<i>Founder, DesignCave</i>)
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="event d-flex flex-wrap align-items-center justify-content-between mt-5">
                        <div className="eventdetails2 d-flex flex-wrap  align-items-center justify-content-center mb-2 ">
                            <div className="details p-2">
                                <p className="mt-3 ">
                                    <span> <strong>Program: </strong></span>Design Talk Day. </p>
                                <p className="mt-3 ">
                                    <span> <strong>Theme: </strong></span> Designing with AI: The Future of Intelligent Interfaces</p>
                                <p className="mt-3">
                                    <span> <strong>Insight: </strong></span>Artificial Intelligence is reshaping the design industry. This talk explores how designers can leverage AI to enhance UX, automate workflows, and create intuitive, intelligent interfaces that adapt to user needs.
                                </p>
                                <p className="mt-3">
                                    <span> <strong>Guest Speaker: </strong></span> Name will be updated. </p>
                            </div>
                            <div className="date2">
                                <p className="mt-3">APR 25</p>
                            </div>
                        </div>
                        <div className="horizontal"></div>

                        <div className="eventdetails2 d-flex flex-wrap align-items-center justify-content-center mb-2 ">
                            <div className="date ">
                                <p className="mt-3">MAY 30</p>
                            </div>
                            <div className="details p-2 ">
                                <p className="mt-3 ">
                                    <span> <strong>Program: </strong></span>Design Talk Day. </p>
                                <p className="mt-3 ">
                                    <span> <strong>Theme: </strong></span>Inclusive Design: Creating for Everyone.</p>
                                <p className="mt-3 ">
                                    <span> <strong>Insight: </strong></span>Accessibility and inclusivity should be at the core of design. This session will explore the principles of inclusive design, practical steps to make digital products accessible, and how designing for diversity benefits all users.
                                </p>
                                <p className="mt-3">
                                    <span> <strong>Guest Speaker: </strong></span>Name will be updated. </p>
                            </div>
                        </div>
                    </div>

                    <div className="event d-flex flex-wrap align-items-center justify-content-between mt-5">
                        <div className="eventdetails2 d-flex flex-wrap  align-items-center justify-content-center mb-2 ">
                            <div className="details p-2">
                                <p className="mt-3 ">
                                    <span> <strong>Program: </strong></span>Design Talk Day. </p>
                                <p className="mt-3 ">
                                    <span> <strong>Theme: </strong></span>The Art of Prototyping: Testing Your Ideas</p>
                                <p className="mt-3">
                                    <span> <strong>Insight: </strong></span>Prototyping helps you bring your ideas to life and test them before full development. We’ll dive into simple steps for creating prototypes and why they’re crucial for getting feedback early in the design process.
                                </p>
                                <p className="mt-3">
                                    <span> <strong>Guest Speaker: </strong></span> Name will be updated. </p>
                            </div>
                            <div className="date2">
                                <p className="mt-3">JUNE 27</p>
                            </div>
                        </div>
                        <div className="horizontal"></div>

                        <div className="eventdetails2 d-flex flex-wrap align-items-center justify-content-center mb-2 ">
                            <div className="date ">
                                <p className="mt-3">JULY 25</p>
                            </div>
                            <div className="details p-2 ">
                                <p className="mt-3 ">
                                    <span> <strong>Program: </strong></span>Design Talk Day. </p>
                                <p className="mt-3 ">
                                    <span> <strong>Theme: </strong></span>Microinteractions: Small Details, Big Impact</p>
                                <p className="mt-3 ">
                                    <span> <strong>Insight:</strong></span>Microinteractions enhance user experience by making interfaces feel intuitive and delightful. This talk will explore their role in UX, how to design them effectively, and real-world examples of microinteractions done right.
                                </p>
                                <p className="mt-3">
                                    <span> <strong>Guest Speaker: </strong></span>Name will be updated. </p>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
            <script async src="https://eocampaign1.com/form/b8ab6c36-bc82-11ef-89f5-85263dcbd4b2.js" data-form="b8ab6c36-bc82-11ef-89f5-85263dcbd4b2"></script>
        </div>
    );
}

export default Event